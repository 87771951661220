import { createReducer } from '../utils/misc';
import {
  FETCH_FEATURE_FLAGS,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_FAILURE,
} from '../constants/index';

const initialState = {
  data: null,             // Feature flags storage
  status: {
    isLoading: false,   // Loading state
    error: null,        // Error state
    lastError: null,    // Keeps track of last error even after new requests
  },
};

export default createReducer(initialState, {
  [FETCH_FEATURE_FLAGS]: (state) => ({
    ...state,
    status: {
      ...state.status,
      isLoading: true,
      error: null,
    }
  }),

  [FETCH_FEATURE_FLAGS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      data: payload,
      status: {
        ...state.status,
        isLoading: false,
        error: null,
      }
    };
  },

  [FETCH_FEATURE_FLAGS_FAILURE]: (state, error) => ({
    ...state,
    status: {
      ...state.status,
      isLoading: false,
      error,
      lastError: error,
    }
  }),
});
