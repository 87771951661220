import { FETCH_FEATURE_FLAGS, FETCH_FEATURE_FLAGS_FAILURE, FETCH_FEATURE_FLAGS_SUCCESS } from "@/constants";
import { default as featureFlagsService } from "@/services/featureFlags";

import { Dispatch } from 'redux';

export const fetchFeatureFlags = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FEATURE_FLAGS });

    try {
      featureFlagsService.fetchFeatureFlags().then(flags => {
        dispatch({
          type: FETCH_FEATURE_FLAGS_SUCCESS,
          payload: flags
        });
      });
    } catch (error) {
      dispatch({
        type: FETCH_FEATURE_FLAGS_FAILURE,
        error: error
      });
    }
  }
}
