import { combineReducers } from 'redux';
import auth from './auth';
import profile from './profile';
import settings from './settings';

import contracts from './contracts';
import contractsGas from './contractsGas';
import electricityContractation from "./electricityContractation";
import gasContractation from "./gasContractation";
import invoices from './invoices';
import invoicesGas from './invoicesGas';
import invoicesMultipoint from './invoicesMultipoint';
import contact from './contact';
import { atcTipusReducer, atcSubtipusReducer } from './atcReducer';
import ibansReducer from "./ibans";
import CRM from "./CRM";
import coupons from "./coupons";
import bateriaVirtual from './bateriaVirtual';
import banners from './banners';
import featureFlags from './featureFlags';
import signup from "./signup";
import { LOGOUT_SUCCESS } from '../constants/index';

const invoicesReducer = combineReducers({
    electricity: invoices,
    gas: invoicesGas,
    multipoint: invoicesMultipoint
});

export const appReducer = combineReducers({
    auth,
    profile,
    settings,
    bateriaVirtual,
    contracts,
    contractsGas,
    electricityContractation,
    gasContractation,
    invoices: invoicesReducer,
    contact,
    coupons,
    atcTipus: atcTipusReducer,
    atcSubtipus: atcSubtipusReducer,
    ibans: ibansReducer,
    CRMCases: CRM,
    banners,
    featureFlags,
    signup
});

// const rootReducer = (state, action) => {
//     if (action.type === LOGOUT_SUCCESS) {
//     // Clear all states except routing
//         Object.keys(state).forEach((oneState, index) => {
//             if (oneState !== 'routing') { state[oneState] = undefined; }
//         });
//     }
//     return appReducer(state, action);
// };

// export default rootReducer;
